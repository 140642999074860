import { defineStore } from 'pinia'

export interface ActionButtonState {
  isShoot: boolean,
  isShootTutorial: boolean,
  touchStart: boolean,
  showJoystick: boolean,
  disabled: boolean
}

const initialState = (): ActionButtonState => ({
  isShoot: false,
  isShootTutorial: false,
  touchStart: false,
  showJoystick: false,
  disabled: true
})

export const actionButtonState = defineStore('actionButtonState', {
  state: initialState,
})
