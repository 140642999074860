<template>
  <div class="start-stats-box">
    <start-countdown-time
      v-if="windState.showWind"
      text="Time to start"
      :time="timer"
      :style="{position: 'absolute', left: '50%', top: '1%',
               margin: '0.25em 0px'}"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  StartCountdownTime,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import {
  windState,
  startPhaseState,
  type StartPhaseState
} from '@/stores'

export default defineComponent({
  name: 'StartStatsBox',
  components: {
    StartCountdownTime
  },
  mixins: [WindowAspect],
  data() {

    return {
      timer: 0,
      windState: windState(),
      startPhaseState: startPhaseState(),
    }

  },
  watch: {
    startPhaseState: {
      handler(value: StartPhaseState) {

        if (Math.ceil(value.startTimer) !== this.timer) {

          this.timer = Math.ceil(value.startTimer)

        }

      },
      deep: true,
      immediate: true
    }
  }
})
</script>

<style lang="less" scoped>
</style>
