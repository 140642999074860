import {
  pathAssetsGlobal,
  pathAssets
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.athleteRaceWhiteMan]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoMan]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackMan]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackWoman]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothes]: {
    ext: 'ktx2',
    version: 240206,
    femaleVersion: 240206,
    genderActive: true,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent1]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240206,
    femaleVersion: 240206,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent2]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240206,
    femaleVersion: 240206,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent3]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240206,
    femaleVersion: 240206,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent4]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240206,
    femaleVersion: 240206,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent5]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240206,
    femaleVersion: 240206,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteEquipment]: {
    ext: 'ktx2',
    version: 6,
    dir: `${texturesDir}/athlete/`,
  },
  [TexturesNames.athleteHair]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradient]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradientVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.gradient]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.concrete]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lights]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.metal]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.panel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.seats]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.stairs]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.trapNet]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.grassUncut]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.paving]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.trees]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.glass]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lattice]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.plastic]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.npc]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.screens]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.screensVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flags]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flagGradient]: {
    ext: 'ktx2',
    returnTextureLightmap: true,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.ppsGrass]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.skybox]: {
    ext: 'ktx2',
    version: 241016,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding1]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding2]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.envLightmap]: {
    ext: 'ktx2',
    version: 231102,
    dir: `${texturesDir}/env/`,
    returnTextureLightmap: true
  },
  [TexturesNames.pigeonSmokeParticles]: {
    ext: 'png',
    version: 2,
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.gunShotParticles]: {
    ext: 'png',
    dir: `${texturesDir}/env/`
  },
}
