import {
  THREE,
  game
} from '@powerplay/core-minigames'

/**
 * wrapper pre 3d objekty
 */
export class ObjectWrapper {

  /** Grupa 3D objektov */
  private group = new THREE.Group()

  /**
   * Vyberieme objekty zo sceny a dame ich do wrappera
   * @param position - pozicia kam wrapper umiestnime
   * @param objects - objekty do wrappera
   */
  public set(position: THREE.Vector3, ...objects: THREE.Object3D[]): void {

    for (let i = 0; i < objects.length; i++) {

      this.group.add(objects[i])

    }

    game.scene.add(this.group)
    this.group.position.copy(position)
    this.group.matrixAutoUpdate = true

  }

  /**
   * Getter na poziciu
   * @returns - Pozicia vo vektore
   */
  public getPosition(): THREE.Vector3 {

    return this.group.position.clone()

  }

  /**
   * nastavime lookAt
   * @param position - pozicia kam sa pozerame
   */
  public lookAt(position: THREE.Vector3): void {

    this.group.lookAt(position)

  }

  /**
   * vyberieme veci z wrappera do sceny a vyhodime wrapper zo sceny
   */
  public destroy(): void {

    // musime ist od posledneho prvku, lebo pouzitim metody add sa vymaze ten prvok a uz tam neni
    for (let i = this.group.children.length - 1; i >= 0; i -= 1) {

      game.scene.add(this.group.children[i])

    }
    game.scene.remove(this.group)

  }

}
