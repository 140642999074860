import { type DisplayMessage } from '@/app/types'
import {
  actionButtonState,
  inputsState,
  movementState,
  startMessageState,
  textMessageState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private textMessagesLinesFinishedEmits = [false, false, false]

  public disableInputs(): void {

    inputsState().disabled = true
    actionButtonState().disabled = true

  }

  public hideButtons(): void {

    actionButtonState().$patch({
      isShoot: false,
      disabled: false
    })
    inputsState().$patch({
      isVisible: false,
      disabled: true
    })

  }

  public enableInputs(disabled = false): void {

    actionButtonState().disabled = !disabled
    inputsState().$patch({
      isVisible: true,
      disabled
    })

  }

  public showStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: true,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    actionButtonState().disabled = false

  }

  public disableStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

  /**
   * Resetovanie text message dokoncenych emitov
   * @param activeFirst - ci je aktivny prvy riadok
   * @param activeSecond - ci je aktivny druhy riadok
   * @param activeThird - ci je aktivny treti riadok
   */
  public resetTextMessageFinishedEmits(activeFirst: boolean, activeSecond: boolean, activeThird: boolean): void {

    this.hideAllTextMessages()
    this.textMessagesLinesFinishedEmits = [!activeFirst, !activeSecond, !activeThird]

  }

  /**
   * Schovanie text message hlasky
   * @param lineNumber - Cislo riadku, ktore malo emit konca
   */
  public hideTextMessage(lineNumber: number): void {

    this.textMessagesLinesFinishedEmits[lineNumber - 1] = true

    if (
      this.textMessagesLinesFinishedEmits[0] &&
      this.textMessagesLinesFinishedEmits[1] &&
      this.textMessagesLinesFinishedEmits[2]
    ) {

      this.hideAllTextMessages()

    }

  }

  /**
   * Schovanie vsetkych message hlasok
   */
  public hideAllTextMessages(): void {

    textMessageState().$patch({
      showFirstLine: false,
      showSecondLine: false,
      showThirdLine: false,
      firstLineText: '',
      firstLineTextType: 0,
      secondLineTextType: 0,
      secondLineLeftNumber: 0,
      showMessage: false,
      showType: 0
    })

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
