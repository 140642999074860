import { defineStore } from 'pinia'

export interface MainState {
  maxSpeed: number,
  speed: number
}

const initialState = (): MainState => ({
  maxSpeed: 1,
  speed: 0
})

export const mainState = defineStore('mainState', {
  state: initialState,
})
