<template>
  <mobile-button
    :type="type"
    :is-scaled="false"
    :disabled="actionButtonState.disabled"
    class="pointer-events-auto"
    :disabled-animation-active="!actionButtonState.isShoot"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui-ssm'
import { inputsManager } from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { actionButtonState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    MobileButton
  },
  data() {

    return {
      actionButtonState: actionButtonState(),
    }

  },
  computed: {
    type() {

      return actionButtonState().isShoot ? 'shoot' : 'call'

    }
  },
  methods: {
    performAction() {

      if (actionButtonState().disabled) return

      disciplinePhasesManager.phaseAim.handleInputs(true)



    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
