/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  athlete = 'athlete',
  env = 'env',
  envDynamic = 'envDynamic',
  trackLines = 'trackLines',
  flags = 'flags',
  skyboxBuildings = 'SkyboxBuildings',
  cameraBoxes = 'cameraBoxes',

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {

  athleteRacePrefix = 'athlete_race_',
  athleteRaceWhiteMan = 'male/athlete_race_white',
  athleteRaceMulattoMan = 'male/athlete_race_mulatto',
  athleteRaceBlackMan = 'male/athlete_race_black',
  athleteRaceWhiteWoman = 'female/athlete_race_white',
  athleteRaceMulattoWoman = 'female/athlete_race_mulatto',
  athleteRaceBlackWoman = 'female/athlete_race_black',
  athleteClothes = 'athlete_clothes',
  athleteClothesOpponent1 = 'athlete_clothes_opponent_1v',
  athleteClothesOpponent2 = 'athlete_clothes_opponent_2v',
  athleteClothesOpponent3 = 'athlete_clothes_opponent_3v',
  athleteClothesOpponent4 = 'athlete_clothes_opponent_4v',
  athleteClothesOpponent5 = 'athlete_clothes_opponent_5v',
  athleteEquipment = 'athleteEquipment',
  athleteHair = 'athleteHair',
  audience = 'audience512v',
  colorGradient = 'ColorGradientAds2048v',
  colorGradientVshosting = 'ColorGradientAdsVshosting2048',
  gradient = 'Gradient64',
  concrete = 'CleanConcrete512',
  ground = 'GroundTexture512',
  lights = 'Emissive512',
  metal = 'MetalDetail512',
  panel = 'Panel512',
  seats = 'Seats512v',
  stairs = 'Stairs512',
  texts = 'SummerGamesText1024',
  trapNet = 'TrapNet512',
  grassUncut = 'GrassUncut512',
  numbers = 'NumbersTemp512',
  paving = 'Paving512',
  trees = 'Trees1024v',
  glass = 'GlassPanel512',
  lattice = 'Lattice512',
  plastic = 'PlasticDetail512',
  npc = 'NPC512',
  screens = 'Screens1024v',
  screensVshosting = 'ScreensVshosting1024',
  flags = 'FlagsImages2048',
  flagGradient = 'FlagGradient256',
  ppsGrass = 'PPSgrass1024',
  skybox = 'Skybox2048v',
  skyboxBuilding1 = 'SkyboxBuildings1_512',
  skyboxBuilding2 = 'SkyboxBuildings2_512',
  envLightmap = 'EnvLightmap2048v',
  smallSnow = 'snowDust',
  pigeonSmokeParticles = 'pigeonSmokeParticles',
  gunShotParticles = 'gunShotParticles',

}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {

  athlete = 'athlete',
  athleteEquipment = 'athleteEquipment',
  athleteHair = 'athleteHair',
  athleteEquipmentOpponent1 = 'athleteEquipmentOpponent1',
  athleteEquipmentOpponent2 = 'athleteEquipmentOpponent2',
  athleteEquipmentOpponent3 = 'athleteEquipmentOpponent3',
  athleteEquipmentOpponent4 = 'athleteEquipmentOpponent4',
  athleteEquipmentOpponent5 = 'athleteEquipmentOpponent5',
  athleteOpponentPrefix = 'athlete_opponent_',
  athleteOpponent1 = 'athlete_opponent_1',
  athleteOpponent2 = 'athlete_opponent_2',
  athleteOpponent3 = 'athlete_opponent_3',
  athleteOpponent4 = 'athlete_opponent_4',
  athleteOpponent5 = 'athlete_opponent_5',
  athleteHairOpponent1 = 'athleteHairOpponent1',
  athleteHairOpponent2 = 'athleteHairOpponent2',
  athleteHairOpponent3 = 'athleteHairOpponent3',
  athleteHairOpponent4 = 'athleteHairOpponent4',
  athleteHairOpponent5 = 'athleteHairOpponent5',
  athleteHairOpponentPrefix = 'athleteHairOpponent',
  audience = 'audience',
  colorGradient = 'colorGradient',
  concrete = 'concrete',
  ground = 'ground',
  lights = 'emissive512',
  metal = 'metal',
  panel = 'panel',
  seats = 'seats',
  stairs = 'stairs',
  texts = 'texts',
  trapNet = 'trapNet',
  grassUncut = 'grassUncut',
  numbers = 'numbers',
  paving = 'paving',
  trees = 'trees',
  glass = 'glass',
  lattice = 'lattice',
  plastic = 'plastic',
  screens = 'screens',
  npc = 'npc',
  flags = 'flags',
  pigeonGlow = 'pigeonGlow',
  flagGradient = 'FlagGradient',
  ppsGrass = 'PPSgrass',
  skyboxBuilding1 = 'skyboxBuilding1',
  skyboxBuilding2 = 'skyboxBuilding2',
  muzzleFlash = 'muzzleFlash',
  onlyVertexColors = 'onlyVertexColors'

}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  happy1 = 'happy 1',
  happy2 = 'happy 2',
  happy3 = 'happy 3',
  fistPump = 'happy 5',
  idle = 'idle',
  loading = 'loading',
  prepare = 'prepare',
  sad = 'sad',
  stand = 'stand',
  unloading = 'unloading',
  introClapDown = 'intro clap down',
  introClapUp = 'intro clap up',
  introHandL = 'intro L hand',
  shoot = 'shoot',
  afterEmotion = 'after emotion',
  afterHappy = 'after happy 3'

}

/** Mena vsetkych animacii luku */
export enum GunAnimationsNames {

  gunIdleHappy = 'gun idle happy',
  gunLoadWalk = 'gun load walk stand sad neutral intro',
  gunPrepare = 'gun prepare',
  gunUnloading = 'gun unloading',
  gunShoot = 'gun shoot'

}

/** Typy verzii minihier */
export enum MinigameVersionTypes {

  a = 'A',
  b = 'B',
  c = 'C',
  none = 'none'

}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  nothing: unknown,
  type: MinigameVersionTypes

}

/**
 * Ideals pre kameru
 */
export interface CameraIdeals {
  idealOffset?: THREE.Vector3,
  idealLookAt?: THREE.Vector3,
  coefSize?: number,
  changeLerp?: number,
  staticMovement?: boolean,
}
