import {
  THREE,
  type MaterialDataObject
} from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.athlete]: {
    meshesArray: [
      'body_low', 'hat_2_low', 'hands', 'f_body_low', 'f_hat_2_low',
      'headphones_low', 'glasses_low', 'f_headphones_low', 'f_glasses_low'
    ],
    textureName: TexturesNames.athleteClothes,
    playerIndex: 0
  },
  [MaterialsNames.athleteOpponent1]: {
    meshesArray: [
      'body_low_opponent_1', 'f_body_low_opponent_1'
    ],
    textureName: TexturesNames.athleteClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.athleteOpponent2]: {
    meshesArray: [
      'body_low_opponent_2', 'f_body_low_opponent_2'
    ],
    textureName: TexturesNames.athleteClothesOpponent2,
    playerIndex: 2

  },
  [MaterialsNames.athleteOpponent3]: {
    meshesArray: [
      'body_low_opponent_3', 'f_body_low_opponent_3'
    ],
    textureName: TexturesNames.athleteClothesOpponent3,
    playerIndex: 3

  },
  [MaterialsNames.athleteOpponent4]: {
    meshesArray: [
      'body_low_opponent_4', 'f_body_low_opponent_4'
    ],
    textureName: TexturesNames.athleteClothesOpponent4,
    playerIndex: 4

  },
  [MaterialsNames.athleteOpponent5]: {
    meshesArray: [
      'body_low_opponent_5', 'f_body_low_opponent_5'
    ],
    textureName: TexturesNames.athleteClothesOpponent5,
    playerIndex: 5

  },
  [MaterialsNames.athleteEquipment]: {
    meshesArray: [
      'gun_low',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent1]: {
    meshesArray: [
      'gun_low_opponent_1',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent2]: {
    meshesArray: [
      'gun_low_opponent_2',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent3]: {
    meshesArray: [
      'gun_low_opponent_3',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent4]: {
    meshesArray: [
      'gun_low_opponent_4',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent5]: {
    meshesArray: [
      'gun_low_opponent_5',
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteHair]: {
    meshesArray: ['hair_low', 'tail_low', 'f_hair_low', 'f_tail_low'],
    textureName: TexturesNames.athleteHair,
    transparent: true
  },
  [MaterialsNames.athleteHairOpponent1]: {
    meshesArray: ['hair_low_opponent_1', 'tail_low_opponent_1', 'f_hair_low_opponent_1', 'f_tail_low_opponent_1'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent2]: {
    meshesArray: ['hair_low_opponent_2', 'tail_low_opponent_2', 'f_hair_low_opponent_2', 'f_tail_low_opponent_2'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent3]: {
    meshesArray: ['hair_low_opponent_3', 'tail_low_opponent_3', 'f_hair_low_opponent_3', 'f_tail_low_opponent_3'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent4]: {
    meshesArray: ['hair_low_opponent_4', 'tail_low_opponent_4', 'f_hair_low_opponent_4', 'f_tail_low_opponent_4'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent5]: {
    meshesArray: ['hair_low_opponent_5', 'tail_low_opponent_5', 'f_hair_low_opponent_5', 'f_tail_low_opponent_5'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.audience]: {
    textureName: TexturesNames.audience,
    meshesArray: ['Trap_Mesh_12'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.colorGradient]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: ['Trap_Mesh_4'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.concrete]: {
    textureName: TexturesNames.concrete,
    meshesArray: ['Trap_Mesh_5'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.lights]: {
    textureName: TexturesNames.lights,
    meshesArray: ['Trap_Mesh_11'],
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.metal]: {
    textureName: TexturesNames.metal,
    meshesArray: ['Trap_Mesh_6'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.panel]: {
    textureName: TexturesNames.panel,
    meshesArray: ['Trap_Mesh_3'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.seats]: {
    textureName: TexturesNames.seats,
    meshesArray: ['Trap_Mesh_14'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
    alpha: 0.5, // alphatest
    // transparent: true
  },
  [MaterialsNames.stairs]: {
    textureName: TexturesNames.stairs,
    meshesArray: ['Trap_Mesh_13'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.trapNet]: {
    textureName: TexturesNames.trapNet,
    meshesArray: ['Trap_Mesh_8'],
    lightmap: TexturesNames.envLightmap,
    // alpha: 0.5, // alphatest
    // opacity: 0.8,
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.grassUncut]: {
    textureName: TexturesNames.grassUncut,
    meshesArray: ['Trap_Mesh'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.paving]: {
    textureName: TexturesNames.paving,
    meshesArray: ['Trap_Mesh_2'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.trees]: {
    textureName: TexturesNames.trees,
    meshesArray: ['Trap_Mesh_1'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    vertexColors: true,
  },
  [MaterialsNames.glass]: {
    textureName: TexturesNames.glass,
    meshesArray: ['Trap_Mesh_9'],
    transparent: true,
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.lattice]: {
    textureName: TexturesNames.lattice,
    meshesArray: ['Trap_Mesh_10'],
    alpha: 0.5, // alphatest
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.plastic]: {
    textureName: TexturesNames.plastic,
    meshesArray: ['envDynamic_Target', 'envDynamic_TargetFragments'],
    vertexColors: true,
  },
  [MaterialsNames.npc]: {
    textureName: TexturesNames.npc,
    meshesArray: ['Trap_Mesh_15'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.screens]: {
    textureName: TexturesNames.screens,
    meshesArray: ['Trap_Mesh_16']
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['flags_Flag_Base'],
    lightmap: TexturesNames.flagGradient
  },
  [MaterialsNames.ppsGrass]: {
    textureName: TexturesNames.ppsGrass,
    transparent: true,
    meshesArray: ['Trap_Mesh_17'],
    lightmap: TexturesNames.envLightmap
  },
  [MaterialsNames.pigeonGlow]: {
    textureName: TexturesNames.gradient,
    meshesArray: ['envDynamic_TargetGlow'],
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    opacity: 0.5,
    transparent: true
  },
  [MaterialsNames.skyboxBuilding1]: {
    textureName: TexturesNames.skyboxBuilding1,
    meshesArray: ['Buildings_Mesh_1'],
    alpha: 0.5
  },
  [MaterialsNames.skyboxBuilding2]: {
    textureName: TexturesNames.skyboxBuilding2,
    meshesArray: ['Buildings_Mesh'],
    alpha: 0.5
  },
  [MaterialsNames.muzzleFlash]: {
    textureName: TexturesNames.gradient,
    meshesArray: ['envDynamic_MuzzleFlash'],
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    opacity: 0.5,
    transparent: true
  },
  [MaterialsNames.onlyVertexColors]: {
    isDefault: true,
    meshesArray: [''],
    vertexColors: true
  }

}
