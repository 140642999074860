import { defineStore } from 'pinia'

export interface ShellsState {
  shellsLeft: number,
  showShells: boolean
}

const initialState = (): ShellsState => ({
  shellsLeft: 0,
  showShells: false
})

export const shellsState = defineStore('shellsState', {
  state: initialState,
})
