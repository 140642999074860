import { defineStore } from 'pinia'


export interface CountdownState {
  isEnabled: boolean
  text: string
  duration: number
  headerText: string,
  showBig: boolean,
  showSmall: boolean,
  leftStart: number,
  showHeader: boolean
}

const initialState = (): CountdownState => ({
  isEnabled: false,
  text: '',
  duration: 1,
  headerText: '',
  showBig: true,
  showSmall: true,
  leftStart: -0.2,
  showHeader: true
})

export const countdownState = defineStore('countdownState', {
  state: initialState,
})
