import {
  gameSettingsState,
  loadingState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui-ssm'
import { SectionNames } from '../../types'
import { tutorialDefaultStates } from './TutorialDefaultState'
import {
  blurState,
  tutorialState,
  uiState
} from '@/stores'
import {
  MobileDetector,
  requestManager,
  tutorialManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'

/**
 * Informacie pre UI priapdne kontrolne prvky na zmenu UI roznych taskov
 */
export class TutorialUIChange {

  /** Meno prave beziacej sekcie */
  private actualSectionName: SectionNames | undefined

  /** Hack lebo nulty task nespusta intro */
  private blurActive = false

  /** Samotny stav ktory chceme vo roznych krokoch tutorialu */
  private uiStateInternal = {
    [SectionNames.mobileSidePick]: () => {

      console.log('SECTION FIRST')
      if (!this.blurActive) {

        blurState().isActive = true
        this.blurActive = true

      }
      this.setMobile(true)

      // dame prec loading
      loadingState().showLoading = false

    },
    [SectionNames.startSection]: () => {

      this.resetTypeWrite()
      this.setMobile(false)
      this.setMessage(true, `tutorialText${requestManager.disciplineID}-1`)
      this.setAnne(true)

      // dame prec loading (pre web)
      loadingState().showLoading = false

      if (MobileDetector.isMobile()) uiState().joystickActive = true
      // audioManager.play(AudioNames.commentatorIntro)

    },
    [SectionNames.startSectionSecond]: () => {

      this.resetTypeWrite()
      this.setMessage(true, `tutorialText${requestManager.disciplineID}-2`)
      this.setAnne(true)

    },
    [SectionNames.startSectionThird]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFourth]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFifth]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
  }

  /** Referencia na vue */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private vm: any

  /**
   * Nastavenie Vue referencie
   * @param vm - vue referencia
   */
  public registerVM(vm: unknown): void {

    this.vm = vm

  }

  /**
   * Zena UI podla aktualnej sekcie
   * @param sectionName - Meno aktualnej sekcie
   */
  private changeUi(sectionName: SectionNames): void {

    this.uiStateInternal[sectionName]()

  }

  /**
   * Resetnutie typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  /**
   * Inicializovanie
   */
  public init(): void {

    // Ak bude treba.

  }

  /**
   * Hlavna logika ui zmeny
   */
  private tutorialUILogic(): void {

    const sectionName = tutorialManager.getActualSectionName() as SectionNames
    if (sectionName && sectionName !== this.actualSectionName) {

      this.changeUi(sectionName)
      this.actualSectionName = sectionName

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    tutorialDefaultStates.update()
    this.tutorialUILogic()

  }

  /**
   * Nastavenie Anny
   * @param showAnne - Ci sa ma zobrazit
   * @param isLeft - Ci sa ma umiestnit vpravo
   * @returns this
   */
  public setAnne(
    showAnne: boolean,
    isLeft = gameSettingsState().isLeft
  ): this {

    tutorialState().anne = {
      showAnne,
      isRight: isLeft
    }

    return this

  }

  /**
   * Nastavenie hlasky pre tutorial
   * @param showMessage - ci sa ma zobrazit hlaska
   * @param message - text
   * @param color - farba
   * @param yellowTextSpecial - specialny text klikni na XYZ
   * @returns this
   */
  public setMessage(
    showMessage: boolean,
    message = '',
    color = TutorialMessageColors.blank,
    yellowTextSpecial = '',
    offset = false
  ): this {

    let yellowText = this.vm.$i18n.t('clickToContinueTemp')
    if (MobileDetector.isMobile()) yellowText = this.vm.$i18n.t('tapToContinue')
    if (yellowTextSpecial) {

      yellowText = this.vm.$i18n.t('tapToButton').replace(
        '[BUTTON]',
        this.vm.$i18n.t(yellowTextSpecial)
      )

    }

    tutorialState().tutorialMessage = {
      showMessage,
      message,
      color,
      yellowText,
      offset,
      yellowTextSpecial: yellowTextSpecial !== ''
    }

    return this

  }

  /**
   * Nastavenie mobilnej uvodnej obrazovky
   * @param show - Ci ma byt zobrazena
   * @returns this
   */
  public setMobile(show: boolean): this {

    tutorialState().mobile = show
    return this

  }

}

export const tutorialUIChange = new TutorialUIChange()
