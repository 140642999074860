import { defineStore } from 'pinia'
import type { MiniTableRowData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/MiniTable/MiniTable.vue'

export interface MiniTableState {
  rowsData: MiniTableRowData[],
  show: boolean
}

const initialState = (): MiniTableState => ({
  rowsData: [],
  show: false
})

export const miniTableState = defineStore('miniTableState', {
  state: initialState,
})
