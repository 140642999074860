import {
  startPhaseState,
  windState
} from '@/stores'
import { shootingConfig } from './config'
import { fpsManager } from '@powerplay/core-minigames'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'

/**
 * Trieda pre casovy limit
 */
export class TimeLimitManager {

  /** Pocitadlo frameov pre casovy limit */
  private frameCounter = 0

  /** Ci je aktivny */
  private isActive = false

  /**
   * Nastavenie aktivnosti casoveho limitu
   * @param active - Ci sa ma aktivovat alebo deaktivovat
   */
  public setActive(active: boolean): void {

    this.isActive = active

  }

  /**
   * Skontrolovanie casoveho limitu
   * @returns Ci uz skoncil casovy limit
   */
  public check(): boolean {

    if (!this.isActive) return false

    this.frameCounter += 1
    startPhaseState().startTimer = (shootingConfig.timeLimitShot - this.frameCounter) / fpsManager.fpsLimit

    // kontrolujeme ci sa ma uz skoncit na zaklade casoveho limitu
    if (this.frameCounter >= shootingConfig.timeLimitShot) {

      windState().showWind = false
      console.log('CASOVY LIMIT UPLYNUL')
      console.log('TUTO SA VYSTRELI HOLUB')
      disciplinePhasesManager.phaseAim.releasePigeon()
      return true

    }

    return false

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.frameCounter = 0
    this.isActive = false

  }

}

export const timeLimitManager = new TimeLimitManager()
