import { defineStore } from 'pinia'
import type { BlueBoxTextType } from '@/app/types'

export interface UiState {
  showTimeKeeper: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  blueBoxTextType: BlueBoxTextType,
  showEsc: boolean,
  joystickActive: boolean
}

const initialState = (): UiState => ({
  showTimeKeeper: false,
  showTrainingLayout: false,
  isTraining: false,
  blueBoxTextType: -1,
  showEsc: false,
  joystickActive: false
})

export const uiState = defineStore('uiState', {
  state: initialState,
})
