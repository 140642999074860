<template>
  <div
    class="under-menu-component"
    :class="[isMobile() ? 'mobile-under-menu-component' : '']"
    :style="transformCoef"
  >
    <div class="component-item">
      <shells-counter
        :time="9"
      />
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'

// Game Components
import ShellsCounter from '../ShellsCounter.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ShellsCounter
  },
  mixins: [WindowAspect],
  computed: {
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': '100% 0'
      }

    }
  }
})

</script>

<style lang="less" scoped>

.under-menu-component{
    position: absolute;
    right: 1%;
    top: 17%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.mobile-under-menu-component {
        top: 17%;
    }
    .component-item {
        position: relative;
        margin-bottom: 15px;
    }

    .visibility-none {
        visibility: hidden;
    }

}

</style>
