import {
  modes,
  playersManager,
  type PlayerInfo,
  corePhasesManager
} from '@powerplay/core-minigames'
import { Opponent } from './Opponent'
import { opponentConfig } from '@/app/config'
import { PlayerStates } from '@/app/types'

/** Trieda pre spravu protihracov */
export class OpponentsManager {

  /** Pole superov */
  private opponents: Opponent[] = []

  /** pocitadlo framov */
  private frameCounter = 0

  /** true ak v danom mode su opponenti */
  private isActive = true

  /**
   * Vrati pocet superov
   * @returns Pocet superov
   */
  public getOpponentsCount(): number {

    return this.opponents.length

  }

  /**
   * Vytvorenie superov
   */
  public create(): void {

    // nastavime si, kedy su superi neaktivni
    if (modes.isTutorial() || modes.isTrainingMode() || modes.isDailyLeague() || modes.isTournament()) {

      this.isActive = false

    }

    if (!this.isActive) return
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 5

    }).forEach((playerInfo: PlayerInfo, index: number) => {

      console.warn(playerInfo.uuid)
      this.opponents[index] = new Opponent(playerInfo.uuid, index + 1)
      this.opponents[index].create(
        `Opponent_${ playerInfo.uuid}`,
        opponentConfig.createData[index + 1].position,
        opponentConfig.createData[index + 1].rotation
      )
      this.opponents[index].createTools()

    })

  }

  /**
   * Nastavenie stavu pre vsetkych superov
   * @param state - Stav ktory chceme nastavit
   */
  public setStateForAllOpponents(state: PlayerStates): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.setState(state)

    })

  }

  /**
   * Nastavenie stavu
   * @param state - Stav ktory chceme nastavit
   */
  public setState(index: number, state: PlayerStates): void {

    if (!this.isActive) return

    this.opponents[index].setState(state)

  }

  /**
   * aktualizovanie superov pred fyzikou
   */
  public updateBeforePhysics(): void {

    if (!this.isActive) return

    this.frameCounter += 1

    this.opponents.forEach((opponent) => {

      opponent.updateBeforePhysics()

    })

  }

  /**
   * aktualizovanie superov po fyzikou
   */
  public updateAfterPhysics(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAfterPhysics()

    })

  }

  /**
   * Aktualizovanie animacii superov
   * @param delta - delta pre animacie
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAnimations(delta)

    })

  }

  /**
   * Getter
   * @returns oppontnes
   */
  public getOpponents(): Opponent[] {

    return this.isActive ? this.opponents : []

  }

  /**
   * Getter
   * @returns uuids
   */
  public getOpponentsIds(): string[] {

    const uuids = [] as string[]
    playersManager.players.filter((playerInfo: PlayerInfo) => {

      return !playerInfo.playable

    }).forEach((playerInfo: PlayerInfo) => {

      uuids.push(playerInfo.uuid)

    })
    return uuids

  }

  /**
   * Nastavenie pozicie hracov na zaciatku pokusu
   */
  public setPositionOnAttemptStart(): void {

    const actualAttempt = corePhasesManager.disciplineActualAttempt - 1

    for (let i = 0; i < this.opponents.length; i++) {

      let posIndex = (1 + (i + (actualAttempt % 5))) % opponentConfig.createData.length
      if (modes.isEventBossFight() && actualAttempt === 4) posIndex = 0
      this.opponents[i].athleteObject.position.x = opponentConfig.createData[posIndex].position.x
      this.opponents[i].athleteObject.position.z = opponentConfig.createData[posIndex].position.z

    }

  }

  /**
   * Aktualizovanie animacii superov
   * @param hardReset - True, ak sa resetuje na dalsiu hru, nie iba na dalsi pokus v hre
   *
   */
  public reset(hardReset = false): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.reset(hardReset)

    })

  }

}

export const opponentsManager = new OpponentsManager()
