import { trainingState } from '@/stores'
import {
  trainingManager,
  modes,
  corePhasesManager,
  requestManager
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { shootingConfig } from '@/app/config'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Pole poctu pokusov na kazdy task */
  public attempts = [0, 0, 0, 0, 0, 0]

  /** Ci je nasledujuci pokus opakujuci alebo nie */
  public nextAttemptAsRepeat = false

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 5

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }
  /**
   * Zapocitanie strely
   * @param hitValue - hodnota vystrelu
   */
  public countShotPoints(hitValue: number): void {

    const task = Tasks[`shot${corePhasesManager.disciplineActualAttempt}` as keyof typeof Tasks]

    let taskValue = 0

    const firstAttempt = trainingTasks.attempts[corePhasesManager.disciplineActualAttempt] === 0
    const { first, second, percentsFirst, percentsSecond } = shootingConfig.shotPoints
    if (hitValue === first) taskValue = firstAttempt ? percentsFirst.firstAttempt : percentsSecond.firstAttempt
    if (hitValue === second) taskValue = firstAttempt ? percentsFirst.secondAttempt : percentsSecond.secondAttempt

    console.log(`${task} zapocitany, hodnota: ${taskValue}, prvy pokus? ${firstAttempt}`)
    this.saveTaskValue(task, taskValue / 100)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}, ${trainingManager.getTpPerTask()}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask${requestManager.disciplineID}-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    // this.saveTaskValue(Tasks.timeSecondShots, this.getTimeSecondShots())

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    trainingManager.resetAll()
    this.attempts = [0, 0, 0, 0, 0, 0]
    this.nextAttemptAsRepeat = false

  }

}

export const trainingTasks = new TrainingTasks()
