/** Typy vystrelu */
export enum ShootingTypes {

  hold = 'hold',
  press = 'press',
  bar = 'bar'

}

/** Smery */
export enum Direction {

  'N' = 0,
  'Np1' = 1,
  'NEm1' = 2,
  'NE' = 3,
  'NEp1' = 4,
  'Em1' = 5,
  'E' = 6,
  'Ep1' = 7,
  'SEm1' = 8,
  'SE' = 9,
  'SEp1' = 10,
  'Sm1' = 11,
  'S' = 12,
  'Sp1' = 13,
  'SWm1' = 14,
  'SW' = 15,
  'SWp1' = 16,
  'Wm1' = 17,
  'W' = 18,
  'Wp1' = 19,
  'NWm1' = 20,
  'NW' = 21,
  'NWp1' = 22,
  'Nm1' = 23

}