<template>
  <section
    class="positioner"
    @touchstart="touchStart"
    @mousedown="touchStart"
    @mouseup="endAction"
    @touchend="endAction"
  >
    <section
      v-if="!gameSettingsState.isLeft"
      class="flex justify-between positioning"
    >
      <movement-control
        :style="[
          {transform: `scale(${1})`, width: '100%'},
          shootPosition,
          {opacity: inputsState.isVisible ? 1 : 0}
        ]"
        class="bottom-left"
      />
      <section v-show="!inputsState.isVisible" />
      <section
        :style="{position: 'absolute', right: '0', bottom: '0', 'pointer-events': 'none'}"
      >
        <div
          class="bottom-right"
          :style="{transform: `scale(${scaleCoef})`}"
        >
          <action-button
            v-if="!movementButtonsState.isActive && inputsState.isVisible && showAction"
          />
        </div>
      </section>
    </section>
    <section
      v-else
      class="flex justify-between positioning"
    >
      <section
        :style="{position: 'absolute', left: '0', bottom: '0', 'pointer-events': 'none'}"
      >
        <div
          class="bottom-left"
          :style="{transform: `scale(${scaleCoef})`}"
        >
          <action-button
            v-if="!movementButtonsState.isActive && inputsState.isVisible && showAction"
          />
        </div>
      </section>
      <section v-show="!inputsState.isVisible" />
      <movement-control
        :style="[
          {transform: `scale(${1})`, width: '100%'},
          shootPosition,
          {opacity: inputsState.isVisible ? 1 : 0}
        ]"
        class="bottom-right"
      />
    </section>
  </section>
</template>

<script lang="ts">
import MovementControl from './MovementControl.vue'
import ActionButton from './ActionButton.vue'
import {
  gameSettingsState,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { inputsManager } from '@powerplay/core-minigames'
import {
  actionButtonState,
  movementButtonsState,
  inputsState
} from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementControl,
    ActionButton
  },
  mixins: [WindowAspect],
  data() {

    return {
      movementButtonsState: movementButtonsState(),
      inputsState: inputsState(),
      gameSettingsState: gameSettingsState()
    }

  },
  computed: {
    movementControlPosition() {

      return {
        position: 'absolute',
        height: '100%'
      }

    },
    shootPosition() {

      let left = `calc(50% - (572px * ${this.scaleCoef} / 2))`
      let right = ''
      if (
        !this.gameSettingsState.isLeft && !this.gameSettingsState.oneFinger ||
        this.gameSettingsState.isLeft && this.gameSettingsState.oneFinger
      ) {

        left = ''
        right = `calc(50% - (572px * ${this.scaleCoef} / 2))`

      }

      return {
        position: 'absolute',
        left,
        right,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `calc(50% - (572px * ${this.scaleCoef} / 2))`,
        height: '100%'
      }

    },
    showAction() {

      return !this.gameSettingsState.oneFinger

    }
  },
  methods: {
    touchStart() {

      if (
        !actionButtonState().showJoystick ||
        actionButtonState().disabled ||
        !this.gameSettingsState.oneFinger
      ) return
      disciplinePhasesManager.phaseAim.handleInputs(true, true)

      console.warn('TouchStart')

    },
    endAction() {

      if (!this.gameSettingsState.oneFinger) return
      console.warn('EndAction')
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style lang="less" scoped>
  .positioner {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    pointer-events: auto;
    transform: none;
  }

  .positioning {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .relative {
    position: relative;
  }

  .pointer-events-auto {
    pointer-events: auto;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }
  .bottom-right {
    transform-origin: bottom right;
    display: flex;
    justify-content: flex-end;
  }
  .bottom-left {
    transform-origin: bottom left;
    display: flex;
    justify-content: flex-start;
  }

</style>
