/** Interface pre manazer faz v discipline */
export interface DisciplinePhaseManager {

  preparePhase(): void;
  startPhase(): void;
  finishPhase(forced: boolean): void;
  update(): void;
  setFinishPhaseTween(): void;
  reset(): void

}

/** Disciplinove fazy */
export enum DisciplinePhases {

  preStart = 0,
  start = 1,
  draw = 2,
  aim = 3,
  shooting = 4,
  finish = 5,
  end = 6

}
