<template>
  <div>
    <power-stick
      v-if="uiState.joystickActive"
      :glow="glow"
      :disabled="disabled"
      :only-horizontal="false"
      :style="{pointerEvents: !inputsState.isVisible ? 'none' : 'auto'}"
      @touchstart.stop="touchStart"
      @position-x="changeMovementX"
      @position-y="changeMovementY"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">

import {
  gameSettingsState,
  PowerStick
} from '@powerplay/core-minigames-ui-ssm'
import { inputsManager } from '@powerplay/core-minigames'
import {
  inputsState,
  movementState,
  uiState
} from '@/stores'
import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  components: {
    PowerStick,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    glow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {

    return {
      uiState: uiState(),
      inputsState: inputsState(),
    }

  },
  methods: {
    touchStart() {

      return

    },
    endContact() {

      if (gameSettingsState().oneFinger) disciplinePhasesManager.phaseAim.handleInputs(true, false, true)
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      movementState().$patch({
        positionX: 0,
        positionY: 0
      })

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      movementState().positionX = positionX

    },
    changeMovementY(positionY: number) {

      if (this.disabled) return

      movementState().positionY = positionY

    }

  }
})

</script>
